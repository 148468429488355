import { makeAuthorizedRequest } from './requests';
import { formQueryString } from './helpers';
import { DEFAULT_PAGE_SIZE } from '../constants/pageSizes';

export function getCarePlan(patientId) {
  return makeAuthorizedRequest('GET', `/patients/${patientId}/care-plan`);
}

export function getNewProblemsList(patientId, params) {
  return makeAuthorizedRequest('GET', `/patients/${patientId}/new-problems`, params);
}

export function getDiagnosesList(patientId, params) {
  return makeAuthorizedRequest('GET', `/patients/${patientId}/diagnoses`, params);
}

export function getMedicationsList(patientId) {
  return makeAuthorizedRequest('GET', `/patients/${patientId}/medications`);
}

export function getMedications(patientId) {
  return makeAuthorizedRequest('GET',
    `/patients/${patientId}/medications`);
}

export function getDiagnosticsList({
  patientId, pageSize = null, currentPage = null, onlyRecent = false,
}) {
  return makeAuthorizedRequest('GET',
    `/patients/${patientId}/diagnostics?${pageSize !== null && currentPage !== null ? `pageSize=${pageSize}&pageNumber=${currentPage}&` : ''}recent=${onlyRecent}`);
}

export function saveGeneralInfo(data, patientId) {
  return makeAuthorizedRequest('PUT', `/patients/${patientId}/general-info`, data);
}

export function changePatientStatus(patientId, data) {
  return makeAuthorizedRequest('PUT', `/patients/${patientId}/status`, data);
}

export function changePatientStatusV2(patientId, data) {
  return makeAuthorizedRequest('PUT', `/patients/${patientId}/status/V2`, data);
}

export function enrollPatient(patientId, data) {
  return makeAuthorizedRequest('PUT', `/patients/${patientId}/enroll`, data);
}

export function saveChoiceInput(endpoint, data, patientId) {
  return makeAuthorizedRequest('PUT', endpoint.replace('{patientId}', patientId), data);
}

export function createGoal(carePlanId, goal) {
  return makeAuthorizedRequest('POST', `/care-plan/${carePlanId}/goals`, goal);
}

export function updateGoal(goal) {
  return makeAuthorizedRequest('PUT', `/goals/${goal.id}`, goal);
}

export function deleteGoal(goal) {
  return makeAuthorizedRequest('DELETE', `/goals/${goal.id}`, goal);
}

export function createGoalProgress(goalId, progress) {
  return makeAuthorizedRequest('POST', `/goals/${goalId}/progress`, progress);
}

export function updateGoalProgress(goalId, progress) {
  return makeAuthorizedRequest('PUT', `/goals/${goalId}/progress/${progress.id}`, progress);
}

export function deleteGoalProgress(goalId, progress) {
  return makeAuthorizedRequest('DELETE', `/goals/${goalId}/progress/${progress.id}`, progress);
}

export function createNextAppointment(appointment) {
  return makeAuthorizedRequest('POST', '/next-appointments', appointment);
}

export function updateNextAppointment(appointment) {
  return makeAuthorizedRequest('PUT', `/next-appointments/${appointment.id}`, appointment);
}

export function createMedication(patientId, medication) {
  return makeAuthorizedRequest('POST', `/patients/${patientId}/medications`, medication);
}

export function updateMedication(medication) {
  return makeAuthorizedRequest('PUT', `/medications/${medication.id}`, medication);
}

export function deleteMedication(medication) {
  return makeAuthorizedRequest('DELETE', `/medications/${medication.id}`, medication);
}

export function createNote(carePlanId, note) {
  return makeAuthorizedRequest('POST', `/care-plan/${carePlanId}/notes`, note);
}

export function updateNote(patientId, note) {
  return makeAuthorizedRequest('PUT', `/patients/${patientId}/summary-notes`, note);
}

export function deleteNote(note) {
  return makeAuthorizedRequest('DELETE', `/notes/${note.id}`, note);
}

export function getActivities(patientId, params) {
  let queryString = '';

  Object.keys(params).forEach((name) => {
    if (params[name] || params[name] === 0) {
      if (!queryString) {
        queryString += '?';
      } else {
        queryString += '&';
      }
      queryString += `${name}=${encodeURIComponent(params[name])}`;
    }
  });

  return makeAuthorizedRequest('GET', `/patients/${patientId}/activities${queryString}`);
}

export function getActivity(activityId) {
  return makeAuthorizedRequest('GET', `/activities/${activityId}`, activityId);
}

export function createBulkActivities(patientId, activities) {
  return makeAuthorizedRequest('POST', `/patients/${patientId}/activities/bulk`, activities);
}

export function createActivity(patientId, activity) {
  return makeAuthorizedRequest('POST', `/patients/${patientId}/activities`, activity);
}

export function updateActivity(activity) {
  return makeAuthorizedRequest('PUT', `/activities/${activity.id}`, activity);
}

export function deleteActivity(activity) {
  return makeAuthorizedRequest('DELETE', `/activities/${activity.id}`);
}

export function getPatientSummary(patientId) {
  return makeAuthorizedRequest('GET', `/patients/${patientId}/summary`);
}

export function getPatientInfo(patientId) {
  return makeAuthorizedRequest('GET', `/patients/${patientId}/general-info`);
}

export function getPatientHistory(patientId) {
  return makeAuthorizedRequest('GET', `/patients/${patientId}/history`);
}

export function finalizeCarePlan(patientId) {
  return makeAuthorizedRequest('POST', `/patients/${patientId}/care-plan/finalize`);
}

export function createCarePlanPdf(patientId) {
  return makeAuthorizedRequest('GET', `/patients/${patientId}/care-plan/pdf`);
}

export function createActivitiesPdf(patientId, params) {
  let queryString = '';

  Object.keys(params).forEach((name) => {
    if (params[name] || params[name] === 0) {
      if (!queryString) {
        queryString += '?';
      } else {
        queryString += '&';
      }
      queryString += `${name}=${encodeURIComponent(params[name])}`;
    }
  });

  return makeAuthorizedRequest('GET', `/patients/${patientId}/activities/pdf${queryString}`);
}

export function createActivityPdf(activityId) {
  return makeAuthorizedRequest('GET', `/activities/${activityId}/pdf`);
}

export function resendBillingReport(patientId) {
  return makeAuthorizedRequest('POST', `/patients/${patientId}/resenddft`);
}

export function getBillingInfo(patientId) {
  return makeAuthorizedRequest('GET', `/patients/${patientId}/billinginfo`);
}

export function setBillingInfo(patientId, data, forceFlag) {
  return makeAuthorizedRequest('PUT', `/patients/${patientId}/billinginfo${forceFlag ? '?force=true' : ''}`, data);
}

export function setContactStatus(patientId, value) {
  return makeAuthorizedRequest('PUT', `/patients/${patientId}/billinginfo/contactstatus`, { value });
}

export function setOnHold(patientId, data) {
  return makeAuthorizedRequest('PUT', `/patients/${patientId}/billinginfo/onhold`, data);
}

export function setComplexCCMStatus(patientId, data, forceFlag = false) {
  return makeAuthorizedRequest('PUT', `/patients/${patientId}/billing/complexccm${forceFlag ? '?force=true' : ''}`, data);
}

export function createIntervention(patientId, intervention) {
  return makeAuthorizedRequest('POST', `/patients/${patientId}/interventions`, intervention);
}

export function updateIntervention(intervention) {
  return makeAuthorizedRequest('PUT', `/interventions/${intervention.id}`, intervention);
}

export function deleteIntervention(interventionId) {
  return makeAuthorizedRequest('DELETE', `/interventions/${interventionId}`);
}

export function setActivityStatus(patientId, data) {
  return makeAuthorizedRequest('PUT', `/patients/${patientId}/activitystatus`, data);
}

export function getBillingHistory(patientId) {
  return makeAuthorizedRequest('GET', `/patients/${patientId}/billinghistory`);
}

export function getBillingHistoryReports(patientId, params = {}) {
  return makeAuthorizedRequest('GET', `/patients/${patientId}/billing-reports${formQueryString(params)}`);
}

export function getBillingHistoryReportsFile(patientId, params = {}) {
  return makeAuthorizedRequest('GET', `/patients/${patientId}/billing-reports/excel${formQueryString(params)}`);
}

export function updateNextActionDate(patientId, nextActionDateReminderInfo) {
  return makeAuthorizedRequest('PUT', `/patients/${patientId}/nextactiondate`, nextActionDateReminderInfo);
}

export function addPatientReportedVitals(patientId, vitals) {
  return makeAuthorizedRequest('POST', `/patients/${patientId}/vitals`, vitals);
}

export function updatePatientReportedVitals(patientId, vitals) {
  return makeAuthorizedRequest('PUT', `/patients/${patientId}/vitals`, vitals);
}

export function addPatientQaNote(patientId, qaNote) {
  return makeAuthorizedRequest('POST', `/patients/${patientId}/qa-notes`, qaNote);
}

export function updatePatientQaNote(patientId, qaNote) {
  return makeAuthorizedRequest('PUT', `/patients/${patientId}/qa-notes`, qaNote);
}

export function getPatientProfile(patientId) {
  return makeAuthorizedRequest('GET', `/patients/${patientId}/profile`);
}

export function savePatientProfile(patientId, profile) {
  return makeAuthorizedRequest('POST', `/patients/${patientId}/profile`, profile);
}

export function updatePatientProfile(patientId, profile) {
  return makeAuthorizedRequest('PUT', `/patients/${patientId}/profile`, profile);
}

export function getPatientReminders(patientId) {
  return makeAuthorizedRequest('GET', `/patients/${patientId}/reminders`);
}

export function addPatientReminder(patientId, reminder) {
  return makeAuthorizedRequest('POST', `/patients/${patientId}/reminder`, reminder);
}

export function updatePatientReminder(patientId, reminder) {
  return makeAuthorizedRequest('PUT', `/patients/${patientId}/reminder/${reminder.id}`, reminder);
}

export function getPatientCallNotes(patientId) {
  return makeAuthorizedRequest('GET', `/patients/${patientId}/call-notes`);
}

export function getPatientSingleCallNote(patientId, callNoteId) {
  return makeAuthorizedRequest('GET', `/patients/${patientId}/call-notes/${callNoteId}`);
}

export function addPatientCallNote(patientId, note) {
  return makeAuthorizedRequest('POST', `/patients/${patientId}/call-notes`, note);
}

export function updatePatientCallNote(patientId, note) {
  return makeAuthorizedRequest('PUT', `/patients/${patientId}/call-notes/${note.id}`, note);
}

export function getLetterBatchList() {
  return makeAuthorizedRequest('GET', '/patients/letter/batch');
}

export function createLetterBatch(letterBatch) {
  return makeAuthorizedRequest('POST', '/patients/letter/batch', letterBatch);
}

export function updateLetterBatchStatus(batchId, batchData) {
  return makeAuthorizedRequest('PUT', `/patients/letter/batch/${batchId}/status`, batchData);
}

export function getLetterBatchHistory(batchId) {
  return makeAuthorizedRequest('GET', `/patients/letter/batch/${batchId}/status`);
}

export function getPatientOutreachInfo(patientId) {
  return makeAuthorizedRequest('GET', `/patients/${patientId}/letter/batches`);
}

export function getNodPatientsByDate(cnId, date) {
  return makeAuthorizedRequest('PUT', '/reminders/nod', { userId: cnId, date });
}

export function getLetterBatchFile(batchId) {
  return makeAuthorizedRequest('GET', `/patients/letter/batch/${batchId}/download`);
}

export function getFlightPlanReminders({ pageSize, pageNumber }) {
  return makeAuthorizedRequest('GET', `/flightplan/reminders?pageSize=${pageSize}&pageNumber=${pageNumber}`);
}

export function getFlightPlanRecentWork({ pageSize, pageNumber }) {
  return makeAuthorizedRequest('GET', `/flightplan/recent-work?pageSize=${pageSize}&pageNumber=${pageNumber}`);
}

export function addPatientImmunization(patientId, immunization) {
  return makeAuthorizedRequest('POST', `/patients/${patientId}/immunizations`, immunization);
}

export function updatePatientImmunization(patientId, immunizationsId, immunization) {
  return makeAuthorizedRequest('PUT', `/patients/${patientId}/immunizations/${immunizationsId}`, immunization);
}

export function deletePatientImmunization(patientId, immunizationsId) {
  return makeAuthorizedRequest('DELETE', `/patients/${patientId}/immunizations/${immunizationsId}`);
}

export function getHealthFactors(patientId) {
  return makeAuthorizedRequest('GET', `/patients/${patientId}/factors`);
}

export function updateHealthFactors(patientId, categoryId, factor) {
  return makeAuthorizedRequest('PUT', `/patients/${patientId}/factors/category/${categoryId}`, factor);
}

export function getPatientHistoryStatus(patientId) {
  return makeAuthorizedRequest('GET', `/patients/${patientId}/history/status`);
}

export function updatePatientTags(patientId, tags) {
  return makeAuthorizedRequest('PUT', `/patients/${patientId}/tags`, tags);
}

export function updatePatientListTags({ patientIds, tagIds }) {
  return makeAuthorizedRequest('PUT', '/patientlist/tags', { tagIds, patientIds });
}

export function getPatientMedicalEvents(patientId) {
  return makeAuthorizedRequest('GET', `/patients/${patientId}/events`);
}

export function getPatientEventTypes() {
  return makeAuthorizedRequest('GET', '/eventtypes');
}

export function addPatientMedicalEvents(patientId, medicalEvent) {
  return makeAuthorizedRequest('POST', `/patients/${patientId}/events`, medicalEvent);
}

export function updatePatientMedicalEvents(patientId, medicalEventId, medicalEvent) {
  return makeAuthorizedRequest('PUT', `/patients/${patientId}/events/${medicalEventId}`, medicalEvent);
}

export function deletePatientMedicalEvent(patientId, eventId) {
  return makeAuthorizedRequest('DELETE', `/patients/${patientId}/events/${eventId}`);
}

export function sendAppointmentReminders(patientId) {
  return makeAuthorizedRequest('POST', `/patients/${patientId}/appointment-reminder`);
}

export function updatePatientDemographicsRequest(patientId, demographicsData) {
  return makeAuthorizedRequest('PUT', `/patients/${patientId}/demographics`, demographicsData);
}

export function getPatientDemographics(patientId) {
  return makeAuthorizedRequest('GET', `/patients/${patientId}/demographics`);
}

export function getSurveys(patientId) {
  return makeAuthorizedRequest('GET', `/patients/${patientId}/surveys`);
}

export function createSurveyAttempt(patientId, attempt) {
  return makeAuthorizedRequest('POST', `/patients/${patientId}/surveys/attempts`, attempt);
}

export function editSurveyAttempt(patientId, attemptId, attempt) {
  return makeAuthorizedRequest('POST', `/patients/${patientId}/surveys/attempts/${attemptId}`, attempt);
}

export function createSurveyCompletion(patientId, attempt) {
  return makeAuthorizedRequest('POST', `/patients/${patientId}/surveys/completions`, attempt);
}

export function deleteSurveyAttempt(patientId, outreachId) {
  return makeAuthorizedRequest('DELETE', `/patients/${patientId}/surveys/attempts/${outreachId}`);
}

export function deleteSurveyCompletion(patientId, surveyResponseId) {
  return makeAuthorizedRequest('DELETE', `/patients/${patientId}/surveys/completions/${surveyResponseId}`);
}

export function getPatientsCurrentMonthBirthdays() {
  return makeAuthorizedRequest('GET', '/patients/birthdays');
}

export function getRemindersAvailability() {
  return makeAuthorizedRequest('GET', '/reminders/availability');
}

export function getFinalizeAlerts(patientId) {
  return makeAuthorizedRequest('GET', `/patients/${patientId}/finalize-check`);
}

export function sendRealTimeSatisfactionSurvey(patientId) {
  return makeAuthorizedRequest('POST', `/patients/${patientId}/satisfaction-survey`);
}

export function getPatientEnrollmentHistoryById(patientId) {
  return makeAuthorizedRequest('GET', `/patients/${patientId}/activities/enrollment`);
}

export function refreshEmrReconcile(patientId) {
  return makeAuthorizedRequest('POST', `/patients/${patientId}/emrreconcile`);
}

export function getPatientsHistoryStatus({
  pageSize = DEFAULT_PAGE_SIZE, pageNumber = 0, optionalFilter = null,
}) {
  return makeAuthorizedRequest('POST', `/patients/history/status/to-be-review?pageSize=${pageSize}&pageNumber=${pageNumber}`, optionalFilter);
}

export function updateReviewedStatus(patientId, patientStatusHistoryId, body) {
  return makeAuthorizedRequest('PUT', `/patients/${patientId}/status-history/${patientStatusHistoryId}/review`, body);
}

export function revertPatientStatus(body) {
  return makeAuthorizedRequest('POST', '/patients/status/revert', body);
}

export function getPatientPrompts(patientId) {
  return makeAuthorizedRequest('GET', `/prompts/patients/${patientId}`);
}

export function savePatientPrompts(patientId, actions) {
  return makeAuthorizedRequest('POST', `/prompts/patients/${patientId}/actions`, actions);
}

export function getPatientConsents(patientId) {
  return makeAuthorizedRequest('GET', `/patients/${patientId}/reconsent`);
}

export function createPatientConsent(patientId, body) {
  return makeAuthorizedRequest('POST', `/patients/${patientId}/reconsent`, body);
}

export function getPatientCareTeam(patientId) {
  return makeAuthorizedRequest('GET', `/patients/${patientId}/careteam`);
}

export function saveCareTeam(patientId, careTeam) {
  return makeAuthorizedRequest('POST', `/patients/${patientId}/careteam`, careTeam);
}

export function updateCareTeam(patientId, careteamId, data) {
  return makeAuthorizedRequest('PUT', `/patients/${patientId}/careteam/${careteamId}`, data);
}

export function deleteCareTeam(patientId, careteamId) {
  return makeAuthorizedRequest('DELETE', `/patients/${patientId}/careteam/${careteamId}`);
}

export function getPatientScreenings(patientId) {
  return makeAuthorizedRequest('GET', `/screenings/patients/${patientId}`);
}

export function getPatientScreening(patientScreeningId) {
  return makeAuthorizedRequest('GET', `/screenings/results/${patientScreeningId}`);
}

export function getPatientScreeningTypes() {
  return makeAuthorizedRequest('GET', '/screenings');
}

export function getScreeningType(screeningDefinitionId) {
  return makeAuthorizedRequest('GET', `/screenings/${screeningDefinitionId}`);
}

export function createScreening(patientId, screeningDefinitionId, screening) {
  return makeAuthorizedRequest('POST', `/screenings/${screeningDefinitionId}/patients/${patientId}`, screening);
}

export function getPatientAdditionalInfo(patientId) {
  return makeAuthorizedRequest('GET', `/patients/${patientId}/additionalinfos`);
}

export function addPatientAdditionalInfo(patientId, information) {
  return makeAuthorizedRequest('POST', `/patients/${patientId}/additionalinfos`, information);
}

export function updatePatientAdditionalInfo(patientId, information, additionaInfoId) {
  return makeAuthorizedRequest('PUT', `/patients/${patientId}/additionalinfos/${additionaInfoId}`, information);
}

export function deletePatientAdditionalInfo(patientId, additionaInfoId) {
  return makeAuthorizedRequest('DELETE', `/patients/${patientId}/additionalinfos/${additionaInfoId}`);
}

export function createTimer(patientId, timerData) {
  return makeAuthorizedRequest('POST', `/patients/${patientId}/timer`, timerData);
}

export function endTimer(patientId, timerId, timerData) {
  return makeAuthorizedRequest('POST', `/patients/${patientId}/timer/${timerId}`, timerData);
}
